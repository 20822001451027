.App {
  text-align: center;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url("../res/imgs/bg_image.jpg") no-repeat center center fixed; 
  /* background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-img-avatar {
  border-radius: 50%;
  width: 130px;
  border: 4px solid white;
  margin-bottom: 1%;
}

.vcenter {
  min-height: 12em;
  display: table-cell;
  vertical-align: middle;
}

.App-Nav-Bar {
  background-color: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7));
}

.App-Contact-Icon {
  width: 130px;
}

.App-Footer-Container {
  min-width: 100%;
}

.App-Footer {
  background-color: rgba(0,0,0,0.7);
  min-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 1.5vmin);
  color: white;
}

.App-Parallax-Custom-Background-Image {
  width: auto;
  height: auto;
  background-size: cover;
}

.App-Parallax {
  height: 100%
}

.App-Parallax-Background-Opacity {
  background-color: rgba(248, 247, 216, 0.7);
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
}

.App-img-company-logo {
  border-radius: 50%;
  width: 50px;
}

.App-img-company-logo-no-radius {
  width: 50px;
}

.App-img-logo {
  border-radius: 50%;
  width: 30px;
  margin-right: 2%;
}

.App-img-logo-no-raduis-bg {
  width: 30px;
  margin-right: 2%;
}

.App-img-logo-bg {
  border-radius: 50%;
  background: white;
  width: 30px;
  overflow: hidden;
  margin-right: 2%;
}

.App-img-logo-white-bg {
  /* border-radius: 50%; */
  width: 30px;
  background: rgba(255,255,255, 0.9);
  margin-right: 2%;
}

.App-Div-Text-Align-Events {
  padding: 8px;
}

.App-Events-h1 {
  text-align: center;
  text-transform: uppercase;
  color: #4CAF50;
}

.App-Events-p {
  text-indent: 50px;
  text-align: justify;
  letter-spacing: 1px;
};

.App-Card-Contact-Me {
  text-align: center;
  padding: 5%;
}

.App-Form-Contact-Me {
  padding: 5%;
}

.App-Col-Contact-Me {
  margin: 5%;
}

.App-Icons-Contact-Me {
  margin: 1%;
}
